import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import NavSection from '../../components/nav-section';
import Footer from '../../components/footer';
import VenuesSection from '../../components/venues-section';
import Layout from '../../components/layout';
import Carousel from 'react-bootstrap/Carousel';
import BookingForm from '../../components/booking-form';

class CocktailsAndEventsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packagesSection: 'active',
      takeyourpickSection: '',
      bookingformSection: '',
      venueDate: '',
      venueTime: '',
    };
    this.timeoutID = {};
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.sectionScrollHandler);
    }
  }

  sectionScrollHandler = e => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let packagesSectionPosition =
      document.getElementById('packagesSection') &&
      document.getElementById('packagesSection').offsetTop;
    let takeyourpickSectionPosition =
      document.getElementById('takeyourpickSection') &&
      document.getElementById('takeyourpickSection').offsetTop;
    let bookingformSectionPosition =
      document.getElementById('bookingformSection') &&
      document.getElementById('bookingformSection').offsetTop;

    if (winScroll >= packagesSectionPosition) {
      document.querySelectorAll('.center-btns').forEach(function (el) {
        el.classList.remove('active');
      });
    }
    if (winScroll >= takeyourpickSectionPosition) {
      document.querySelectorAll('.center-btns').forEach(function (el) {
        el.classList.remove('active');
      });
    }
    if (winScroll >= bookingformSectionPosition) {
      document.querySelectorAll('.center-btns').forEach(function (el) {
        el.classList.remove('active');
      });
    }
  };

  scrollHandler = e => {
    this.setState({
      packagesSection: '',
      takeyourpickSection: '',
      bookingformSection: '',
    });
    this.setState({ [e.target.dataset.id]: 'active' });
    var target = document.getElementById(e.target.dataset.id);
    target.scrollIntoView(true);
  };
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
        
    const {
      packagesSection,
      takeyourpickSection,
      bookingformSection,
    } = this.state;

    const {
      pageTitle,
      tabName01,
      tabName02,
      tabName03,
      mobileTabName01,
      mobileTabName02,
      mobileTabName03,
      sliderImages,
      sliderCaption,
      sliderButton1,
      sliderButton2,
      venuesSection,
    } = get(this, 'props.data.allContentfulEventsFourthSubPage.edges')[0].node;

    const imageUrls = sliderImages.map(image => image.file.url)

    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;
    const footer = get(this, 'props.data.allContentfulFooter.edges')
    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Events - Photo &amp; Video Shoots" />

        <div className="venue-header sm-w85">
          <h1 className="mb-0">{pageTitle}</h1>
        </div>
        <section id="link-tabs" className="pt-4 pb-4">
          <div className="container sm-w85">
            <div className="row">
              <div className="col-sm-12">
                <ul className="nav justify-content-center">
                  <li className="font-gtaM">
                    <input
                      data-id="packagesSection"
                      id="btn-packages"
                      className={`center-btns nav-link quick-link ${packagesSection}`}
                      onClick={this.scrollHandler}
                      value={tabName01}
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      data-id="takeyourpickSection"
                      id="btn-press"
                      className={`center-btns nav-link quick-link ${takeyourpickSection}`}
                      onClick={this.scrollHandler}
                      value={tabName02}
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      data-id="bookingformSection"
                      id="btn-brand"
                      className={`center-btns nav-link quick-link ${bookingformSection}`}
                      onClick={this.scrollHandler}
                      value={tabName03}
                      type="button"
                    />
                  </li>

                  {/* SIDENAV for mobile */}
                  <div className="sidenav d-block d-sm-none">
                    <li className={packagesSection}>
                      <input
                        data-id="packagesSection"
                        className={`font-gtaM ${packagesSection} side-scrollers`}
                        id="li-01"
                        onClick={this.scrollHandler}
                        value={mobileTabName01}
                        type="button"
                      />
                    </li>
                    <li className={takeyourpickSection}>
                      <input
                        data-id="takeyourpickSection"
                        className={`font-gtaM ${takeyourpickSection} side-scrollers`}
                        id="li-02"
                        onClick={this.scrollHandler}
                        value={mobileTabName02}
                        type="button"
                      />
                    </li>
                    <li className={bookingformSection}>
                      <input
                        data-id="bookingformSection"
                        className={`font-gtaM ${bookingformSection} side-scrollers`}
                        id="li-03"
                        onClick={this.scrollHandler}
                        value={mobileTabName03}
                        type="button"
                      />
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="packagesSection" className="mt-4 pb-5 mb-4  sm-mt90">

          <Carousel>
            {imageUrls.map((image, index) => (
              <Carousel.Item>
                <div className="container sm-w85">
                  <div className="venue-whole venue-details">
                    <img src={image} alt={index} />
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>

          <div className="container sm-w85">
            <div className="row justify-content-between align-items-center mt40 venue-whole venue-details">
              <div className="col-lg-12 col-md-12 text-center">
                <p className="font-bigcas mt40 mb40">
                  {sliderCaption}
                </p>
                <div className="row action-btns mb-2 justify-content-center">
                  <a href="#bookingForm">
                    <button className="btn">{sliderButton1}</button>
                  </a>
                  <Link to={`/about/faqs-and-guidelines#eventsFaqsGuidelinesSection`}>
                    <button className="btn">{sliderButton2}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="takeyourpickSection" className="sm-w85">
          <VenuesSection 
            venuesSection={venuesSection}
          />
        </section>

        <section id="bookingformSection" className="sm-w85">
          <BookingForm bookingForm={bookingForm}/>
        </section>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default CocktailsAndEventsPage;

export const pageQuery = graphql`
  query CocktailsAndEventsPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulEventsFourthSubPage {
      edges {
        node {
          pageTitle
          sliderButton1
          sliderButton2
          sliderCaption
          sliderImages {
            file {
              url
            }
          }
          tabName01
          tabName02
          tabName03
          mobileTabName01
          mobileTabName02
          mobileTabName03
          venuesSection {
            locationBtn1
            locationBtn2
            locationBtn3
            sectionCaption
            sectionTitle
            locationImage1 {
              file {
                url
              }
            }
            locationImage2 {
              file {
                url
              }
            }
            locationImage3 {
              file {
                url
              }
            }
            locationName1
            locationName2
            locationName3
            sectionCaption
            sectionTitle
          }
        }
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
